import { Component, Input, OnInit } from '@angular/core';
import { isScullyGenerated } from '@scullyio/ng-lib';
import { environment } from 'apps/sss/src/environments/environment';

@Component({
  selector: 'cms-content-status',
  template: `
  <div *ngIf="!isProd && !isStatic && ['draft', 'review', 'publish'].includes(status)" class="fixed bottom-1 right-0 z-50" >
    <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-white bg-gray-600 last:mr-0 mr-1"
          [ngClass]="{ 'bg-red-600': status === 'review', 'bg-green-600': status === 'publish' }">
      {{ status === 'publish' ? 'published' : status }}
    </span>
  </div>
  `,
  styles: [
  ]
})
export class ContentStatusComponent implements OnInit {

  public isStatic = isScullyGenerated();

  @Input()
  status: string;

  public isProd = environment.production

  constructor() { }

  ngOnInit(): void {
  }

}
