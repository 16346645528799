import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { ButtonComponent } from './button/button.component';
import { NavbarComponent } from './navbar/navbar.component';
import { LayoutComponent } from './layout/layout.component';
import { LandingHeroComponent } from './landing-hero/landing-hero.component';
import { LeadingBulletsComponent } from './leading-bullets/leading-bullets.component';
// import { LeadingEUVisibilityComponent } from '../../../../apps/sss/src/app/shared/leading-eu-visibility/leading-eu-visibility.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
  ],
  declarations: [
    HeaderComponent,
    FooterComponent,
    ButtonComponent,
    NavbarComponent,
    LayoutComponent,
    LandingHeroComponent,
    LeadingBulletsComponent,
    // LeadingEUVisibilityComponent
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    ButtonComponent,
    NavbarComponent,
    LayoutComponent,
    LandingHeroComponent,
    LeadingBulletsComponent,
    // LeadingEUVisibilityComponent
  ],
})
export class UiModule {}
