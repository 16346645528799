import { Component, OnInit } from '@angular/core';
import { IdleMonitorService } from '@scullyio/ng-lib';
import { LinksPage } from '../../models/links.page.model';
import { PagesContentService } from '../../services/pages/pages-content.service';

@Component({
  selector: 'app-links-page',
  templateUrl: './links-page.component.html',
  styleUrls: ['./links-page.component.scss']
})
export class LinksPageComponent implements OnInit {

  constructor(
    private ims: IdleMonitorService,
    private content: PagesContentService,
  ) { }

  private linksPage: LinksPage;

  public status: string;
  public title: string;
  public markdownContent: string;
  public links: any;

  public onReadyMarkdownContent(): void {
    console.log('links.onReadyMarkdownContent');
    if (this.linksPage) {
      console.log('links.fireManualMyAppReadyEvent');
      this.ims.fireManualMyAppReadyEvent();
    }
  }

  public async ngOnInit(): Promise<void> {

    const linksPage = await this.content.getLinks();
    this.linksPage = linksPage;

    this.status = linksPage.status;
    this.title = linksPage.title;
    this.markdownContent = linksPage.content;
    this.links = linksPage.links;

    console.log('linksPage', linksPage);

    // NOTE: when markdown block is disabled in the template the fire app ready manually here
    this.onReadyMarkdownContent()
  }

}
