<div class="about-page-wrapper max-w-none pt-6" >

  <cms-content-status [status]="status"></cms-content-status>
  
  <sss-leading-eu-visibility></sss-leading-eu-visibility>

  <div class="container mx-auto max-w-6xl py-4 -mt-8 prose prose-grey prose-about-page lg:prose-lg" >

    <div class="-mt-10 -mb-4 border-b border-gray-200 uppercase pl-4 md:pr-8">
      <h2>{{ title }}</h2>
    </div>

    <div class="flex flex-wrap overflow-hidden">

      <div class="flex flex-col items-start justify-center w-full overflow-hidden">
        <div class="content lg:prose-lg w-full" *ngIf="markdownContent" >

          <div class="bg-top bg-cover bg-no-repeat w-full" style="background-image: url('https://ik.imagekit.io/aglomeracijasinj/assets/icon-for-background-1.svg?updatedAt=1641942142148')" >
            <markdown [data]="markdownContent" (ready)="onReadyMarkdownContent()"></markdown>
          </div>

          <div class="bg-top bg-cover bg-no-repeat w-full" style="background-image: url('https://ik.imagekit.io/aglomeracijasinj/assets/icon-for-background-2.svg?updatedAt=1641942134851')" >
            <markdown [data]="markdownContentGoals" (ready)="onReadyMarkdownContent()"></markdown>
          </div>
              
          <div class="bg-top bg-cover bg-no-repeat w-full" style="background-image: url('https://ik.imagekit.io/aglomeracijasinj/assets/icon-for-background-1.svg?updatedAt=1641942142148')" >
            <markdown [data]="markdownContentArea" (ready)="onReadyMarkdownContent()"></markdown>
          </div>
          
          <div class="w-full" >
            <markdown [data]="markdownContentPurpose" (ready)="onReadyMarkdownContent()"></markdown>
          </div>
          
        </div>
      </div>

    </div>

  </div>

</div>

