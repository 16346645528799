import { Pipe, PipeTransform } from '@angular/core';
import { HelpersService } from '../../../services/helpers/helpers.service';

@Pipe({
  name: 'safeJson'
})
export class SafeJsonPipe implements PipeTransform {

  constructor(
    private helpers: HelpersService
  ) {}

  transform(value: unknown, ...args: unknown[]): unknown {
    return this.helpers.safeStringify(value);
  }

}
