<div class="home-page-wrapper" >

  <cms-content-status [status]="status"></cms-content-status>

  <sss-landing-hero></sss-landing-hero>
  
  <sss-leading-bullets></sss-leading-bullets>
  
  <div class="flex flex-wrap -mt-6 pb-2">

    <gallery class="gallery"
               #gallery
               *ngIf="galleryItems?.length"
               [items]="galleryItems"
               thumbPosition="left"
               [counter]="false"
               [imageSize]="'contain'"
               [nav]="false"
               [dots]="false"
               [autoPlay]="true"
               [slidingDirection]="'horizontal'"
               [playerInterval]="5000"
               (mouseenter)="gallery.stop()"
               (mouseleave)="gallery.play()"
               [thumb]="false"
               [thumbTemplate]="thumbTemplate"
               [itemTemplate]="itemTemplate"></gallery>

  </div>
  
  <div>
    <section class="py-0 md:py-12 overflow-x-hidden">
      <div class="container px-4 mx-auto">
        <div class="flex flex-wrap lg:flex-nowrap">
          <div class="w-full lg:w-1/2">
            <div class="lg:pr-32">
              <div class="w-full overflow-hidden">
                <div class="content prose lg:prose-lg prose-blue" >
                  <markdown [data]="markdownContent" (ready)="onReadyMarkdownContent()"></markdown>

                  <!-- <a *ngIf="markdownContent" routerLink="/informacije-o-projektu" >pročitaj više...</a> -->
                </div>
              </div>
            </div>
          </div>
          <div class="relative w-full lg:w-1/2 my-12 lg:my-0">
            <img class="relative mx-auto rounded-xl w-full z-10" src="https://ik.imagekit.io/aglomeracijasinj/assets/sinj-obuhvat-aglomeracije.png?updatedAt=1641915847271" alt="">
            <img class="absolute top-0 left-0 w-40 -ml-12 -mt-12" src="https://ik.imagekit.io/aglomeracijasinj/assets/aglomeracija-sinj-logo-light.png?updatedAt=1641917032305" alt="">
            <img class="absolute bottom-0 right-0 w-40 -mr-12 -mb-12" src="https://ik.imagekit.io/aglomeracijasinj/assets/aglomeracija-sinj-logo-light.png?updatedAt=1641917032305" alt="">
          </div>
        </div>
      </div>
    </section>
  </div>

  

</div>

