
<ng-container *ngIf="navbarItems" >
  <sss-layout [navbarItems]="navbarItems">
    <div class="" >
      <router-outlet></router-outlet>
    </div>
  </sss-layout>

  <app-accessibility-tools></app-accessibility-tools>
</ng-container>
