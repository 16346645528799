import { Component, OnInit } from '@angular/core';
import { IdleMonitorService } from '@scullyio/ng-lib';

@Component({
  selector: 'app-empty-page',
  template: `
    <div>
      &nbsp;
      <!-- SSS: EMPTY PAGE -->
    </div>
  `,
  styles: [
  ]
})
export class EmptyPageComponent implements OnInit {

  constructor(
    private ims: IdleMonitorService,
  ) { }

  ngOnInit(): void {
    document.body.style.display = 'none'

    setTimeout(() => {
      this.ims.fireManualMyAppReadyEvent()
    })
  }

}
