import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs/operators';
import { NavigationItem } from './models/navigation.model';
import { HelpersService } from './services/helpers/helpers.service';
import { NavigationService } from './services/navigation/navigation.service';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {


  public navbarItems: NavigationItem[];

  public constructor(
    private navigation: NavigationService,
    private helpers: HelpersService,
    private router: Router,
  ) {

    this.init();

  }

  ngAfterViewInit(): void {
    this.hideSplashScreen()
    this.preloadModules()
  }

  private async init(): Promise<void> {

    const mainNavigation = await this.navigation.getMainNavigation();
    // console.log('AppComponent.mainNavigation', mainNavigation);
    this.navbarItems = mainNavigation.items;

    this.listenRouterEvents()
  }

  private listenRouterEvents() {

    // Listen for URL changes at router
    this.router.events
      .pipe(untilDestroyed(this))
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        // Scroll to top on route change
        window.scroll(0,0)
    })
  }

  /**
   * Default hide splash screen after constant time, but every page should manually hide splash screen before.
   * This is just a protection to avoid application stacked in the state with the splash screen if some component/page do not hide it manually.
   */
  private hideSplashScreen(): void {
    setTimeout(() => {
      this.helpers.hideSplashScreen();
    }, 1000);
  }

  /**
   * Some modules are set to be Lazy at app-routing.module.ts.
   * This is nice from faster loading of Home screen but it has negative UX at first Router navigation to these modules.
   * Best combination is to keep then Lazy at Routing module but to load them Async in background when Home page is available.
   */
  private async preloadModules(): Promise<void> {
    setTimeout(() => {
      import('./blog/blog.module').then(m => m.BlogModule)
    }, 0)
  }
}
