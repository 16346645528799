import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { HomePageComponent } from './pages/home-page/home-page.component'
import { AboutPageComponent } from './pages/about-page/about-page.component'
import { ContactPageComponent } from './pages/contact-page/contact-page.component'
import { EmptyPageComponent } from './pages/empty-page/empty-page.component'
import { LinksPageComponent } from './pages/links-page/links-page.component'
import { GalleryPageComponent } from './pages/gallery-page/gallery-page.component'
import { MediaPageComponent } from './pages/media-page/media-page.component'

const routes: Routes = [
  {
    path: 'empty',
    component: EmptyPageComponent
  },
  {
    path: '',
    component: HomePageComponent
  },
  {
    path: 'informacije-o-projektu',
    component: AboutPageComponent
  },
  {
    path: 'novosti',
    loadChildren: () => import('./blog/blog.module').then(m => m.BlogModule)
  },
  // {
  //   path: 'poveznice',
  //   component: LinksPageComponent
  // },
  // {
  //   path: 'galerija',
  //   component: GalleryPageComponent
  // },
  // {
  //   path: 'mediji',
  //   component: MediaPageComponent
  // },
  {
    path: 'kontakt',
    component: ContactPageComponent
  },
  {
    path: '**',
  redirectTo: '/'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
