<div class="landing-hero-wrapper text-center" >
  
  <div class="hero-title py-10 text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-heading leading-normal md:leading-10 pb-4 md:pb-12 -mt-16">
    PROJEKT<br>POBOLJŠANJA<br>VODNO-KOMUNALNE<br>INFRASTRUKTURE
  </div>
  
  <p class="hero-subtitle text-white leading-relaxed text-sm md:text-base px-5 pb-8">Projekt je sufinancirala Europska Unija iz Kohezijskog fonda</p>
  
  <div>
    <a class="inline-block py-4 px-8 mb-4 sm:mb-0 mr-3 text-xs text-white text-center font-semibold leading-none bg-blue-900 hover:bg-blue-800 rounded no-underline" [routerLink]="['/informacije-o-projektu']">Saznaj više</a>
    <a class="inline-block py-4 px-8 text-xs text-blueGray-100 hover:text-blueGray-200 text-center font-semibold leading-none rounded no-underline bg-white" [routerLink]="['/kontakt']">Kontaktiraj nas</a>
  </div>
  
  <div class="eu-visibility rounded md:rounded-lg bg-white m-4 px-2 md:px-4 md:py-2 md:mt-8" >
    <div class="h-2" ></div>
    <a href="https://strukturnifondovi.hr/" target="_blank" >
      <img class="m-0 h-16" src="https://ik.imagekit.io/aglomeracijasinj/assets/eu-vidljivost.svg?updatedAt=1641824520961" alt="Europska unija zajedno do fondova EU">
    </a>
    <div class="h-2" ></div>
  </div>
  
    
  
</div>

