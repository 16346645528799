<div class="contact-page-wrapper max-w-none pt-6">

  <cms-content-status [status]="status"></cms-content-status>

  <sss-leading-eu-visibility></sss-leading-eu-visibility>

  <div class="container mx-auto max-w-6xl py-4 -mt-8 prose prose-grey lg:prose-lg" >

    <div class="-mt-10 -mb-4 border-b border-gray-200 w-full pl-4 md:pr-8 uppercase" *ngIf="title">
      <h2>{{ title }}</h2>
    </div>

    <div class="flex flex-wrap overflow-hidden w-full bg-right-top bg-no-repeat" style="background-image: url('https://ik.imagekit.io/aglomeracijasinj/assets/contact-page-background.svg?updatedAt=1641947141924'); background-size: contain;">

      <div class="flex flex-col items-start justify-center w-full overflow-hidden  ">
        <div class="content prose prose-red lg:prose-lg w-full mt-5 bg-white" >

          <div class="flex flex-wrap items-start overflow-hidden w-full ">

            <div class="w-full overflow-hidden">
              <div style="color: rgba(148,163,184, 100)" class="max-w-md">
                <markdown [data]="markdownContent" (ready)="onReadyMarkdownContent()"></markdown>
              </div>
              
              <div class="px-4" >
                
                <div class="pb-2">
                  <span class="inline-block py-2 px-3 mr-4 text-xs font-semibold bg-gray-700 text-white rounded">ADRESA</span>
                  <a href="https://goo.gl/maps/7SavYVvdLPQaj55r5" target="_blank" >
                    126.brigade Hrvatske vojske 13, 21 230 Sinj
                  </a>
                </div>
                
                <div class="pb-2">
                  <span class="inline-block py-2 px-3 mr-4 text-xs font-semibold bg-gray-700 text-white rounded">WEB</span>
                  <a href="https://www.viock.hr" target="_blank">
                    www.viock.hr
                  </a>
                </div>

                <div class="pb-2">
                  <span class="inline-block py-2 px-3 mr-4 text-xs font-semibold bg-gray-700 text-white rounded">EMAIL</span>
                  <a href="mailto:tajnica@viock.hr" target="_blank">
                    tajnica@viock.hr
                  </a>
                </div>
                  
                <div class="pb-2">
                  <span class="inline-block py-2 px-3 mr-4 text-xs font-semibold bg-gray-700 text-white rounded">TELEFON 1</span>
                  <a href="tel:+38521688154">
                    +385 21 668 154
                  </a>
                </div>
                  
                <div>
                  <span class="inline-block py-2 px-3 mr-4 text-xs font-semibold bg-gray-700 text-white rounded">TELEFON 2</span>
                  <a href="tel:+38521688150">
                    +385 21 668 150
                  </a>
                </div>
                
              </div>
                
            </div>

          </div>

        </div>

      </div>


    </div>

  </div>

</div>
