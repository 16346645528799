import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafeJsonPipe } from './pipes/safe-json/safe-json.pipe';
import { ContentStatusComponent } from './content-status/content-status.component';
import { AccessibilityToolsComponent } from './accessibility-tools/accessibility-tools.component';
import { LeadingEUVisibilityComponent } from './leading-eu-visibility/leading-eu-visibility.component';



@NgModule({
  declarations: [
    SafeJsonPipe,
    ContentStatusComponent,
    AccessibilityToolsComponent,
    LeadingEUVisibilityComponent,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    SafeJsonPipe,
    ContentStatusComponent,
    AccessibilityToolsComponent,
    LeadingEUVisibilityComponent,
  ]
})
export class SharedModule { }
