<div class="links-page-wrapper max-w-none prose lg:prose-lg px-5" >

  <cms-content-status [status]="status"></cms-content-status>

  <ng-container *ngIf="!links" >
    Učitavanje...
  </ng-container>

  <ng-container *ngIf="links" >

    <div class="container mx-auto">
      <div class="-mt-10 mb-5 border-b border-gray-200">
        <h2>{{ title }}</h2>
      </div>
    </div>

    <div class="container mx-auto flex flex-col items-center" >

      <div class="flex items-center justify-center flex-wrap overflow-hidden -mx-2" style="max-width: 1200px;">

        <ng-container *ngFor="let link of links" >
          <div class="my-2 px-2 w-full overflow-hidden md:w-1/2" style="max-width: 512px;">
            <div class="border border-gray-200 rounded-md p-5" >
              <a [href]="link.url" target="_blank" >
                <div class="flex flex-col items-center justify-center h-20 lg:h-32">
                  <!-- <div>{{ link.title }}</div> -->
                  <img [src]="link.image" class="w-48 lg:w-64" [alt]="link.title" [title]="link.title" >
                </div>
              </a>
            </div>
          </div>
        </ng-container>

      </div>

      <!-- <div class="content prose prose-blue lg:prose-lg" *ngIf="markdownContent" >
        <markdown [data]="markdownContent" (ready)="onReadyMarkdownContent()"></markdown>
      </div> -->

    </div>
  </ng-container>

</div>

