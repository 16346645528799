<div class="layout-wrapper">

  <div class="flex flex-wrap">

    <!-- HEADER -->
    <div class="w-full overflow-hidden z-30 bg-white">
      <sss-header>
        <sss-navbar [title]="navbar.title"
                [titleImage]="navbar.titleImage"
                [titleLink]="navbar.titleLink"
                [activeLink]="navbar.activeLink"
                [mainImage]="navbar.mainImage"
                [items]="navbar.items"
                >
        </sss-navbar>
      </sss-header>
    </div>

    <!-- MAIN -->
    <div class="main-section-wrapper w-full z-20 mt-20">
      <main class="">
        <section>
          <ng-content></ng-content>
        </section>
      </main>
    </div>

    <!-- FOOTER -->
    <div class="w-full overflow-hidden">
      <sss-footer></sss-footer>
    </div>

  </div>


</div>
