import { Component, OnDestroy, OnInit } from '@angular/core';
import { IdleMonitorService } from '@scullyio/ng-lib';
import { Gallery, GalleryItem, ImageItem } from 'ng-gallery';
import { Lightbox } from 'ng-gallery/lightbox';
import { PagesContentService } from '../../services/pages/pages-content.service';

@Component({
  selector: 'app-gallery-page',
  templateUrl: './gallery-page.component.html',
  styleUrls: ['./gallery-page.component.scss']
})
export class GalleryPageComponent implements OnInit, OnDestroy {

  public galleryItems: GalleryItem[];

  constructor(
    private ims: IdleMonitorService,
    private content: PagesContentService,
    public gallery: Gallery,
    public lightbox: Lightbox,
  ) { }

  async ngOnInit(): Promise<void> {
    await this.fetchGalleryPage()
  }

  ngOnDestroy() {
    this.gallery.ref('lightbox').destroy();
  }

  private async fetchGalleryPage() {

    // const galleryPage = await this.content.getGallery()
    // this.initGalleryPage(galleryPage.gallery)

    this.ims.fireManualMyAppReadyEvent()
  }

  /**
   * Create gallery component from Flamelink Gallery object
   * @param gallery
   */
  private initGalleryPage(gallery) {
    // TODO: move to helper
    const galleryItems = [];
    for (let galleryItem of gallery) {

      const galleryImage = galleryItem && galleryItem.image && galleryItem.image[0];
      // console.log('galleryImage', galleryImage);

      const title = galleryItem?.title;
      const description = galleryItem?.description;
      const src = galleryImage?.url;
      const thumb = galleryImage?.url;

      const imageItem = new ImageItem({ src, thumb });
      console.log('imageItem', imageItem);
      (imageItem.data as any).title = title;
      (imageItem.data as any).description = description;

      galleryItems.push(imageItem);
      // galleryItems.push({ src, thumb, title });
    }
    this.galleryItems = galleryItems;
    // console.log('this.galleryItems', this.galleryItems);

    this.gallery.ref('lightbox', {
      thumbPosition: 'left',
      imageSize: 'contain',
      thumb: false,

    }).load(this.galleryItems);
  }

}
