<div class="footer-wrapper bg-gray-50 border-t border-b">

  <section class="bg-blue-900 flex flex-col items-center px-4 pt-4 md:py-8">
    <div class="container flex flex-col lg:flex-row flex-justify-center mx-0 py-4 -mb-4 md:mb-0">
      <div class="w-full lg:w-2/3 md:pl-4 md:pr-20 mb-4 md:mb-0">
        <p class="mb-2 text-base md:text-xl font-bold  text-gray-400 leading-relaxed">KORISNIK</p>
        <address class="not-italic">
          <p class="mb-2 text-base md:text-lg font-medium text-white leading-relaxed">Vodovod i odvodnja Cetinske Krajine d.o.o.</p>
          <p class="mb-2 text-base md:text-lg font-medium text-white  leading-relaxed">
            <a href="https://goo.gl/maps/7SavYVvdLPQaj55r5" target="_blank" >126. brigade Hrvatske vojske 13, 21230 Sinj</a>
          </p>
        </address>
        <p class="mb-2 text-base md:text-lg font-medium text-white leading-relaxed">
          <a href="tel:+38521668150" >
            +385 (0) 21 668 150; 
          </a>
          <a href="tel:+38521668154" >
            668 154
          </a>
        </p>
        <p class="mb-2 text-base md:text-lg font-medium text-white leading-relaxed">
          <a href="https://www.viock.hr" target="_blank" >www.viock.hr</a>
        </p>
        <a href="https://www.viock.hr" target="_blank" >
          <img src="https://ik.imagekit.io/aglomeracijasinj/assets/viock-logo.png?updatedAt=1641923921127" alt="VIOCK" class="w-full rounded" style="max-width: 421px;" >
        </a>
      </div>
      <div class="relative w-full lg:w-1/3 my-12 lg:my-0">
        <a href="http://www.sinj.hr/" target="_blank" >
          <img class="mx-auto z-5 w-full" style="min-width: 103px; max-width: 206px;" src="https://ik.imagekit.io/aglomeracijasinj/assets/grad-sinj-logo.png?updatedAt=1641925504684" alt="Grad Sinj">
        </a>
      </div>
    </div>
  </section>

  <footer class="flex flex-col items-center justify-content text-center p-8 pt-16 pb-24">
    <div class="font-semibold">
      Projekt je sufinancirala Europska unija iz Kohezijskog fonda
    </div>

    <div class="mt-4">
      Izradu internetske stranice sufinancirala je Europska unĳa iz Kohezijskog fonda
    </div>

    <div class="flex items-center justify-content">
      <a href="https://strukturnifondovi.hr" target="_blank" >
        <img src="https://ik.imagekit.io/aglomeracijasinj/assets/eu-flag.svg?updatedAt=1641923s842684" alt="" class="w-20 h-15 m-8 mt-4">
      </a>
    </div>
    <div class="w-full lg:w-center text-center mt-0">
      Sva prava pridržana © Copyright by 
      <a href="https://www.viock.hr" target="_blank" >
        <b>VIOCK d.o.o.</b> 
      </a>
      2022.<br>
      <!-- <a href="https://www.viock.hr" target="_blank" >
        <img src="https://ik.imagekit.io/aglomeracijasinj/assets/viock-logo.png?updatedAt=1641923921127" class="max-h-8 mt-4 max-w-full inline-block" >
      </a> -->
    </div>
    <!-- <div>
      <a href="https://strukturnifondovi.hr" target="_blank" >
        <img src="https://ik.imagekit.io/aglomeracijasinj/assets/eu-vidljivost.svg?updatedAt=1641824520961" class="max-h-14 mt-4 max-w-full" >
      </a>
    </div> -->
    <div class="font-light text-xs mt-4">
      Odricanje od odgovornosti: Sadržaj ove internetske stranice isključivo je odgovornost VIOCK d.o.o. i ne odražava nužno gledište Europske unije
    </div>

  </footer>

</div>
