// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyA2gpg-cY9JHdZ6cFUSTSj4a8QvHen-nAY",
    authDomain: "aglomeracija-sinj.firebaseapp.com",
    projectId: "aglomeracija-sinj",
    storageBucket: "aglomeracija-sinj.appspot.com",
    messagingSenderId: "73241178063",
    appId: "1:73241178063:web:7371ccbf5703645a1aecba",
    measurementId: "G-4ZTHTM6YN6"
  },
  // NOTE: implicit STATIC app are at port 1668 and hostname endsWith .netlify.app
  static: {
    hosts: [
      'aglomeracija-sinj.com.hr' // @Netlify
    ]
  },
  // NOTE: implicit DYNAMIC app is at ports 4200 and 1864 and hostname endsWith .web.app
  dynamic: {
    hosts: [
      // NOTE: explicit hostname endsWith .web.app is redundant but only defined as placeholder to be as example
      'aglomeracija-sinj.web.app' // @Firebase Hosting
    ]
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
