<div class="gallery-page-wrapper max-w-none prose lg:prose-lg px-5" >

  <!-- <cms-content-status [status]="status"></cms-content-status> -->

  <ng-container *ngIf="!galleryItems" >
    Učitavanje...
  </ng-container>

  <ng-container>

    <!-- <div class="container mx-auto">
      <div class="-mt-10 mb-5 border-b border-gray-200">
        <h2>Galerija</h2>
      </div>
    </div> -->

    <div class="container mx-auto flex flex-col items-center" >

      <!-- <p>
        Detaljnu foto dokumentaciju projekta možete očekivati uskoro ovdje.
      </p> -->

      <div class="grid">
        <button mat-raised-button mat-ripple class="grid-item" *ngFor="let item of galleryItems; let i = index"
          [lightbox]="i" gallery="lightbox">

          <span class="grid-image" [style.backgroundImage]="'url(' + item.data.thumb + ')'"></span>

        </button>
      </div>

      <div *ngIf="false">
        <gallery id="mixed"
                 *ngIf="galleryItems?.length"
                 gallerize
                 [items]="galleryItems"
                 thumbPosition="left"
                 fluid
                 [thumbTemplate]="thumbTemplate"
                 [itemTemplate]="itemTemplate"></gallery>

        <!-- Add custom template to thumbnails -->
        <ng-template #thumbTemplate let-type="type">
          <!-- <span *ngIf="type === 'youtube'" class="item-type">
            <fa-icon [icon]="youtubeIcon" size="lg"></fa-icon>
          </span> -->
          <!-- <span *ngIf="type === 'video'" class="item-type">
            <fa-icon [icon]="videoIcon" size="lg"></fa-icon>
          </span> -->
        </ng-template>

        <!-- Add custom template to image items -->
        <ng-template #itemTemplate
                     let-index="index"
                     let-type="type"
                     let-data="data"
                     let-currIndex="currIndex">
          <div *ngIf="type === 'image' && index === currIndex && (data?.title || data?.description)" [@slideAnimation] class="item-text absolute bottom-0 right-0 bg-black p-2">
            <b *ngIf="data?.title">{{data?.title}}</b>
            <div *ngIf="data?.description" >{{data?.description}}</div>
          </div>
        </ng-template>
      </div>

    </div>

  </ng-container>
</div>
