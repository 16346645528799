import { Injectable } from '@angular/core';
import { FLNavigation } from 'ng-flamelink';
import { from } from 'rxjs';
import { Navigation } from '../../models/navigation.model';
import { HelpersService } from '../helpers/helpers.service';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  constructor(
    private navigation: FLNavigation,
    private helpers: HelpersService,
  ) { }

  public async getMainNavigation() {
    return this.getNavigation('mainNavigation');
  }

  private async getNavigation(key: string): Promise<Navigation> {

    const navigation$ = this.helpers.safeUseScullyTransferState(`navigation_${key}`, from(this.getNavigationDocument(key)));

    return new Promise((resolve) => {
      const sub = navigation$.subscribe((navigation: Navigation) => {
        // console.log('getNavigation', key, navigation);

        // NOTE: take one value and unsubscribe
        setTimeout(() => {
          sub.unsubscribe();
        });

        if (!navigation) {
          console.error('Navigation with key `mainNavigation` is not found at Flamelink CMS!');
          alert('Please add `mainNavigation` in Flamelink CMS.');
        }

        const data: Navigation = {
          id: navigation.id,
          title: navigation.title,
          items: []
        };

        // Keep only first level of the navigation tree
        for (let item of navigation.items) {
          data.items.push({
            title: item.title,
            link: (item as any).url,
          });
        }

        resolve(data);
      });
    });

  }

  private getNavigationDocument<T>(key: string): Promise<T> {
    /**
     * https://flamelink.github.io/flamelink/#/navigation?id=get
     */
    return this.navigation.get({
      navigationKey: key,
      structure: 'tree',
    })
  }
}
