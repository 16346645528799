import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';

enum AccessibilityToolsCommand {
  ZoomIn,
  ZoomOut,
  GreyScreen,
  HighContrast,
  NegativeContrast,
  LightBackground,
  UnderlineLinks,
  Reset
}

@Component({
  selector: 'app-accessibility-tools',
  templateUrl: './accessibility-tools.component.html',
  styleUrls: ['./accessibility-tools.component.scss'],
  animations: [
    trigger('openClose', [
      state('open', style({
        marginRight: '0px',
      })),
      state('closed', style({
        marginRight: '-198px',
      })),
      transition('* => closed', [
        animate('0.5s')
      ]),
      transition('* => open', [
        animate('0.3s')
      ]),
    ]),
  ],
  encapsulation: ViewEncapsulation.None,
})
export class AccessibilityToolsComponent implements OnInit {

  public isOpen = false;
  // public isOpen = true;

  public Commands = AccessibilityToolsCommand;
  public activeCommands: AccessibilityToolsCommand[] = [];

  public fontScale = 1.0;

  constructor() { }

  ngOnInit(): void {
    // this.toggle( AccessibilityToolsCommand.NegativeContrast);
  }

  toggle(command: AccessibilityToolsCommand): void {

    if (command === AccessibilityToolsCommand.ZoomIn || command === AccessibilityToolsCommand.ZoomOut) {
      const fontStep = 0.1;

      this.fontScale = this.fontScale + (command === AccessibilityToolsCommand.ZoomIn ? fontStep : ((-1) * fontStep));

      this.fontScale = Math.round(this.fontScale * 10) / 10

      if (this.fontScale > 1.5) {
        this.fontScale = 1.5;
      }

      if (this.fontScale < 1.0) {
        this.fontScale = 1.0;
      }

      this.setFontSize(this.fontScale);
    } else {

      if (this.activeCommands.indexOf(command) === -1) {

        if (command !== AccessibilityToolsCommand.UnderlineLinks) {
          if (this.activeCommands.indexOf(AccessibilityToolsCommand.UnderlineLinks) === -1) {
            this.activeCommands = [];
          } else {
            this.activeCommands = [AccessibilityToolsCommand.UnderlineLinks];
          }
        }
        this.activeCommands.push(command);
      } else {
        this.activeCommands.splice(this.activeCommands.indexOf(command), 1);
      }
    }

    if (command === AccessibilityToolsCommand.Reset) {
      this.reset();
    }

    if (this.activeCommands.indexOf(AccessibilityToolsCommand.GreyScreen) !== -1) {
      this.setClassToHtmlElement('grey-screen');
    } else {
      this.removeClassToHtmlElement('grey-screen');
    }

    if (this.activeCommands.indexOf(AccessibilityToolsCommand.HighContrast) !== -1) {
      this.setClassToHtmlElement('high-contrast');
    } else {
      this.removeClassToHtmlElement('high-contrast');
    }

    if (this.activeCommands.indexOf(AccessibilityToolsCommand.NegativeContrast) !== -1) {
      this.setClassToHtmlElement('negative-contrast');
    } else {
      this.removeClassToHtmlElement('negative-contrast');
    }

    if (this.activeCommands.indexOf(AccessibilityToolsCommand.LightBackground) !== -1) {
      this.setClassToHtmlElement('light-background');
    } else {
      this.removeClassToHtmlElement('light-background');
    }

    if (this.activeCommands.indexOf(AccessibilityToolsCommand.UnderlineLinks) !== -1) {
      this.setClassToHtmlElement('underline-links');
    } else {
      this.removeClassToHtmlElement('underline-links');
    }

  }

  private reset(): void {
    this.activeCommands = [];
    this.fontScale = 1.0;
    this.setFontSize(this.fontScale);
    window.scrollTo(0, 0);
  }

  private setFontSize(fontScale: number) {
    const body = document.getElementsByTagName('body')[0];
    body.style.setProperty('zoom', fontScale + '');
  }

  private setClassToHtmlElement(className: string): void {
    const html = document.getElementsByTagName('html')[0];
    html.classList.add(className)
  }

  private removeClassToHtmlElement(className: string): void {
    const html = document.getElementsByTagName('html')[0];
    html.classList.remove(className)
  }
}
