import { Component, OnInit } from '@angular/core';
import { IdleMonitorService } from '@scullyio/ng-lib';

@Component({
  selector: 'app-media-page',
  templateUrl: './media-page.component.html',
  styleUrls: ['./media-page.component.scss']
})
export class MediaPageComponent implements OnInit {

  constructor(
    private ims: IdleMonitorService,
  ) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.ims.fireManualMyAppReadyEvent()
    })
  }

  public isLogosVisible = false
  public isForMediaVisible = false
  public isPublicationsVisible = false

  public logos = [
    // {
    //   title: 'RGB colors',
    //   svg: 'https://firebasestorage.googleapis.com/v0/b/aglomeracija-sinj.appspot.com/o/f',
    //   pdf: 'https://firebasestorage.googleapis.com/v0/b/aglomeracija-sinj.appspot.com/o/f',
    //   png: 'https://firebasestorage.googleapis.com/v0/b/aglomeracija-sinj.appspot.com/o/f',
    // },

  ]

  public forMedia = [
    // {
    //   title: 'TIT',
    //   internal: '/link',
    // }
  ]

  public publications = [
    // {
    //   title: 'Brošura projekta',
    //   external: 'https://firebasestorage.googleapis.com/v0/b/aglomeracija-sinj.appspot.com/o/f',
    // }
  ]

}
