<div class="leading-bullets-wrapper">
  
  <section class="pt-8 md:pt-20 pb-8">
    <div class="container px-4 mx-auto">
      <div class="flex flex-wrap -mx-4">
        <div class="w-full md:w-1/2 lg:w-1/4 mb-6 px-12">
          <div class="text-center">
            <img class="inline-block" src="https://ik.imagekit.io/aglomeracijasinj/assets/aglomeracija-sinj-icon.svg?updatedAt=1641418659138">
            <h4 class="mb-3 text-blue-900 font-semibold font-heading">UKUPNA VRIJEDNOST PROJEKTA</h4>
            <p class="text-blueGray-400 leading-loose">378.337.251,63 HRK</p>
          </div>
        </div>
        <div class="w-full md:w-1/2 lg:w-1/4 mb-6 px-12">
          <div class="text-center">
            <img class="inline-block" src="https://ik.imagekit.io/aglomeracijasinj/assets/aglomeracija-sinj-icon.svg?updatedAt=1641418659138">
            <h4 class="mb-3 text-blue-900 font-semibold font-heading">BESPOVRATNA SREDSTVA OSIGURANA IZ KOHEZIJSKOG FONDA</h4>
            <p class="text-blueGray-400 leading-loose">211.610.086,23 HRK</p>
          </div>
        </div>
        <div class="w-full md:w-1/2 lg:w-1/4 mb-12 px-12">
          <div class="text-center">
            <img class="inline-block" src="https://ik.imagekit.io/aglomeracijasinj/assets/aglomeracija-sinj-icon.svg?updatedAt=1641418659138">
            <h4 class="mb-3 text-blue-900 font-semibold font-heading">NACIONALNA SREDSTVA FINANCIRANJA</h4>
            <p class="text-blueGray-400 leading-loose">91.059.715,07 HRK</p>
          </div>
        </div>
        <div class="w-full md:w-1/2 lg:w-1/4 mb-12 px-12">
          <div class="text-center">
            <img class="inline-block" src="https://ik.imagekit.io/aglomeracijasinj/assets/aglomeracija-sinj-icon.svg?updatedAt=1641418659138">
            <h4 class="mb-3 text-blue-900 font-semibold font-heading">RAZDOBLJE PROVEDBE PROJEKTA</h4>
            <p class="text-blueGray-400 leading-loose">2020. - 2024.</p>
          </div>

        </div>
      </div>
    </div>
  </section>
  
</div>