import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'sss-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  @Input()
  titleImage: string;

  @Input()
  title: string;

  // Split title by ` ` space and first word is titleStart and titleEnd are other words
  titleStart: string;
  titleEnd: string;

  @Input()
  titleLink: string;

  @Input()
  mainImage: string;

  @Input()
  mainImageLink = '#';

  /**
   * Has priority against items[index].isActive === false
   */
  @Input()
  activeLink: string;

  @Input()
  items: { title: string, link: string, isActive: boolean }[]

  @Input()
  isOpenMenu = false;

  constructor(
    private router: Router,
  ) {

  }

  ngOnInit(): void {
    this.router.events.pipe(untilDestroyed(this)).subscribe(event => {

      // console.log('navbar.router.event', event);
      this.markActiveItem();
    });

    this.markActiveItem();

    this.splitTitle();
  }

  /**
   * Check location.pathname and make active correct navbar item
   */
  private markActiveItem(): void {

    if (location.pathname !== this.activeLink) {
      this.activeLink = location.pathname;

      const parts = this.activeLink?.split('/');
      if (parts?.length >= 2) {
        this.activeLink = '/' + parts[1];
      }

      this.isOpenMenu = false;
    }
  }

  private splitTitle(): void {
    const parts = this.title.split(' ');
    this.titleStart = parts[0];
    delete parts[0];
    this.titleEnd = parts.join(' ');
  }

}
